<template>
  <div>
    <div class="container">
      <img src="@/assets/img/course/sj_StudyAbroad/banner.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/1.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/2.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/3.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/4.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/5.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/6.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroad/7.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "consult",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.container {
	padding-top:54px;
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>